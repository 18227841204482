import { useState } from "react";
import { TypeAnimation } from "react-type-animation";
import { scroller } from "react-scroll";
import { Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons"
import "./Introduction.css";

const Introduction = () => {
  const [showParagraph, setShowParagraph] = useState(false);
  const [scrolldownHover, setScrolldownHover] = useState(false);

  const handleScrollDown = () => {
    scroller.scrollTo("about", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <div id="hero">
      <div className="d-flex flex-column justify-content-between">
        <div>
          <span>Creative. </span>
          <span>Self-learner. </span>
          <span>Team Player.</span>
        </div>
        <div className="animated-text">
          <TypeAnimation
            sequence={[
              "My name is Mati and I love writing articles",
              1000,
              "My name is Mati and I love writing poetry",
              1000,
              "My name is Mati and I love writing code",
              1000,
              () => setShowParagraph(true),
            ]}
            speed={50}
            style={{ fontSize: "1.5em" }}
            repeat={0}
          />
        </div>
        <div className="mt-2">
          <Image
            src="../mic-pic.jpg"
            alt="my profile picture"
            fluid
            roundedCircle
            style={{ maxWidth: "25%" }}
            className={`my-pic ${showParagraph ? "visible" : "invisible"}`}
          />
        </div>
        <div
        className={
          showParagraph ? 
          "scrolldown visible mt-3 d-flex justify-content-center mx-auto w-75" : "invisible"
        }
        >
          <div className="mt-2 me-2">
          <FontAwesomeIcon 
          style={{ cursor: "pointer" }}
          onMouseEnter={() => setScrolldownHover(true)}
          onMouseLeave={() => setScrolldownHover(false)}
          onClick={handleScrollDown}
          icon={faArrowDown} 
          size="lg"
          fade={scrolldownHover ? true : false}
          />
          </div>
          <p className=" mt-2">
            Start scrolling down to check out my writing skills
          </p>
        </div>
      </div>
    </div>
  );
};

export default Introduction;