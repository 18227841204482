import { useState } from "react";
import ActiveTabVisual from "./active-tab-visuals/ActiveTabVisual";
import { Tab, Tabs } from "react-bootstrap";
import "./AboutSection.css";

const AboutSection = () => {
  const [activeTab, setActiveTab] = useState("fs-dev");

  const handleTabSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  return (
    <div
      id="about"
      className="about-wrapper d-flex flex-column justify-content-evenly"
    >
      <h2 className={activeTab === "fs-dev" ? "d-block mt-4" : "invisible"}>
        My Current Stack:
      </h2>
      <ActiveTabVisual activeTab={activeTab} />
      <div>
        <Tabs
          defaultActiveKey="fs-dev"
          id="fill-tab-example"
          onSelect={handleTabSelect}
          className="mb-3 ms-2 me-2"
          fill
        >
          <Tab eventKey="fs-dev" title="Fullstack Web Developer">
            <div className="d-flex justify-content-between">
              <p className="tab-content w-75 text-start ms-3">
                I caught the coding bug during a Digital
                Humanities Workshop, when introduced to
                ML-based softwares and their underlying principles. It sparked my interest,
                so I started to self-learn the basics of C
                language, followed by JavaScript & simple frontend development,
                just for fun. Then I decided, why not turn the fun practice into
                a practical career change? I enrolled to ITC's Fullstack
                Development Bootcamp, and the rest is detailed{" "}
                <a
                  href="../Mati_Breski_CV.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="text-light"
                >
                  here
                </a>{" "}
              </p>
              <div className="w-25">
                <a
                  href="../Mati_Breski_CV.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="text-decoration-none"
                >
                  <img src="../cv.png" alt="CV icon" />

                  <p className="cv-file">CV file</p>
                </a>
              </div>
            </div>
          </Tab>
          <Tab eventKey="research" title="Ex-Scholar, constant Researcher">
            <p className="w-75 text-start ms-3">
              I hold an MA in Humanities with the academic distinction Magna Cum Laude. My research revolves around the evolved, distinct nature of human species. As
              a PhD scholar I studied the co-evolution of
              emotions and language, until I evolved to be emotionally enamored with coding languages...
            </p>
          </Tab>
          <Tab eventKey="creativity" title="Writer & Performer">
            <p className="w-75 text-start ms-3 mb-2">
              Here you can find me perform my translation to{" "}
              <a
                href="https://www.youtube.com/watch?v=Jpz_gUyImhw"
                target="_blank"
                rel="noreferrer"
                className="text-light"
              >
                Graceless by The National, my favorite band
              </a>
              , and two songs to which I've contributed my lyrics and vocals.
              {" "}
              <br/>
              <br/>
              I've written for Walla!NBA section (2010 to 2013),
              <a
                href="https://www.google.com/search?q=%D7%9E%D7%AA%D7%99+%D7%91%D7%A8%D7%A1%D7%A7%D7%99+%D7%95%D7%95%D7%90%D7%9C%D7%94+&sca_esv=598892895&sxsrf=ACQVn08roaEpPn216HyeZpxLCRZ7xCP4YQ%3A1705438564325&ei=ZO2mZdGOE5LYxc8PlKK_yAI&udm=&ved=0ahUKEwjR0L_e5eKDAxUSbPEDHRTRDykQ4dUDCBA&uact=5&oq=%D7%9E%D7%AA%D7%99+%D7%91%D7%A8%D7%A1%D7%A7%D7%99+%D7%95%D7%95%D7%90%D7%9C%D7%94+&gs_lp=Egxnd3Mtd2l6LXNlcnAiHdee16rXmSDXkdeo16HXp9eZINeV15XXkNec15QgMgQQIxgnSOMNUKwHWKwHcAJ4AJABAJgBmgGgAZoBqgEDMC4xuAEDyAEA-AEB4gMEGAEgQYgGAQ&sclient=gws-wiz-serp#ip=1"
                target="_blank"
                rel="noreferrer"
                className="text-light"
              >here you can find some of the columns
              </a>{" "}
            </p>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default AboutSection;
