import { Accordion } from "react-bootstrap";

const Research = () => {

  return (
    <div className="pub-wrapper d-flex flex-column">
      <h3>Publications</h3>
      <Accordion className="mt-2 ms-3 me-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            &#8212; Breski, M. & Dor, D. (2021) Are Humans More Like Wolves or
            Dogs? Frontiers for Young Minds
          </Accordion.Header>
          <Accordion.Body>
            <p>
              The article presents a core concept for young readers around the
              world from previously published, co-author worked: Human Social
              Evolution: self-domestication or self-control? (Shilton, Breski,
              Dor & Jablonka, 2020). It talks about Human Social Evolution,
              focusing on its emotional aspects, and how it can be viewed in
              comparison to the social life of other animals: domesticated
              species and ultra-social mammals. The article was reviewed by
              pupils from Sixth grade of Yitzhak Navon School in Yokne’am,
              Israel.
            </p>
            <a
              href="https://kids.frontiersin.org/he/articles/10.3389/frym.2021.751566-he"
              target="_blank"
              rel="noreferrer"
              className="link-to-article text-decoration-none"
            >
              Read More
            </a>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            &#8212; Shilton, D., Breski, M., Dor, D. & Jablonka, E. (2020) Human
            Social Evolution: self-domestication or self-control? Frontiers in
            Psychology
          </Accordion.Header>
          <Accordion.Body>
            <p>
              In this collaborative research work, me and my co-writers extend
              and qualify the prevalent Human Self-Domestication hypothesis, and
              suggest that what underlies human social evolution is selection
              for socially mediated emotional control and plasticity. My unique
              contributions include research and assemblage of Table 1
              (comparison between modern humans, apes, and domesticated and
              non-domesticated canids), which cites 113 publications; and
              writing the Language and Emotional Control sub-section, based on
              my PhD research.
            </p>
            <div className="d-flex justify-content-between">
              <a
                href="https://www.frontiersin.org/articles/10.3389/fpsyg.2020.00134/full"
                target="_blank"
                rel="noreferrer"
                className="link-to-article text-decoration-none align-self-baseline"
              >
                Read More
              </a>
              <a
                href="https://loop-impact.frontiersin.org/impact/article/505032#totalviews/views"
                target="_blank"
                rel="noreferrer"
                className="article-views"
              >
                <img
                  src="../sd-article.png"
                  style={{ width: "150px", height: "150px" }}
                />
              </a>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            &#8212; The Anthropodcast (co-hosted with Prof. Ehud Manor)
          </Accordion.Header>
          <Accordion.Body>
            <iframe
              src="https://podcasters.spotify.com/pod/show/mati1982/embed"
              height="110px"
              width={window.innerWidth - 72}
            ></iframe>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Research;
