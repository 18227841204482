import Marquee from "react-fast-marquee";
import { devSkills } from "./devSkills";
import { Tooltip } from 'react-tooltip';

const FullstackDev = () => {
    return (
        <>
        <Marquee pauseOnHover>
            {devSkills.length > 0 && devSkills.map((skill) => (
                <span key={skill.id} className="m-3">
                    <img 
                    src={skill.img} 
                    alt={skill.name}
                    data-tooltip-id="technology-tooltip"
                    data-tooltip-content={skill.name}
                    />
                </span>
                
            
            ))}
        </Marquee>
        <Tooltip id="technology-tooltip" place="top" />
        </>
        
    )
}

export default FullstackDev;