import { useState } from "react";
import emailjs from "@emailjs/browser";
import { Row, Col, Spinner, Toast } from "react-bootstrap";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const hideToast = () => {
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };
  
  const sendEmail = (e) => {
    e.preventDefault();
    setShowSpinner(true);
    const templateParams = {
      name: name,
      email: email,
      subject: subject,
      message: message,
    };

    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          setShowSpinner(false);
          setShowToast(true);
          setName("");
          setEmail("");
          setSubject("");
          setMessage("");
          hideToast();
        },
        (error) => {
          console.log(error);
          setShowSpinner(false);
        }
      );
    e.target.reset();
  };

  return (
    <form className="contact-form mx-auto" action="#" method="post" onSubmit={sendEmail}>
      <Row className="ms-2 me-3">
        <input
          value={name}
          type="text"
          className="cont"
          id="name"
          name="firstname"
          placeholder="Name"
          required
          onChange={(e) => setName(e.target.value)}
        />
        <input
          value={email}
          type="text"
          id="email"
          className="cont"
          name="email"
          placeholder="Email"
          required
          onChange={(e) => setEmail(e.target.value)}
        />
      </Row>
      <Row className="ms-2 me-3">
        <input
          value={subject}
          type="text"
          id="subjecting"
          className="cont"
          name="subject"
          placeholder="Subject"
          required
          onChange={(e) => setSubject(e.target.value)}
        />{" "}
      </Row>
      <Row className="ms-2 me-3">
        <textarea
          required
          value={message}
          rows="5"
          cols="5"
          id="boxing"
          className="cont"
          placeholder="Message"
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
      </Row>
      <div className="d-flex justify-content-center">
        <Spinner
          animation="grow"
          variant="warning"
          className={showSpinner ? "visible mt-2 me-2" : "invisible"}
        />
        <div className="submit-and-toast d-flex justify-content-evenly mt-3">
        <input
          type="submit"
          value="Send a message"
          id="submit_button"
        />
        <Toast 
        show={showToast}
        >
          <Toast.Body>
            <strong className="me-auto">Message sent</strong>
          </Toast.Body>
        </Toast>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
