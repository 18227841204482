export const devSkills = [
    { id: 1, img: '../html.png', name: 'HTML5' },
    { id: 2, img: '../css.png', name: 'CSS3' },
    { id: 3, img: '../bootstrap.png', name: 'Bootstrap' },
    { id: 4, img: '../javascript.png', name: 'JavaScript (ES9)' },
    { id: 5, img: '../react.png', name: 'React' },
    { id: 6, img: '../typescript.png', name: 'TypeScript' },
    { id: 7, img: '../angular.png', name: 'Angular (v16)' },
    { id: 8, img: '../redux.png', name: 'Redux' },
    { id: 9, img: '../nodejs.png', name: 'NodeJS' },
    { id: 10, img: '../express.png', name: 'Express' },
    { id: 11, img: '../nestjs.png', name: 'NestJS' },
    { id: 12, img: '../my-sql.png', name: 'MySQL' },
    { id: 13, img: '../postgresql.png', name: 'PostgreSQL' },
    { id: 14, img: '../mongodb.png', name: 'MongoDB' },
    { id: 15, img: '../firebase.png', name: 'Firebase' },
    { id: 16, img: '../git.png', name: 'Git' },
    { id: 17, img: '../graphql.png', name: 'GraphQL' },
    { id: 18, img: '../cypress.png', name: 'Cypress' }
  ];
  