import { useState } from "react";
import { Carousel } from "react-bootstrap";

const CreativeContent = () => {

  return (
    <>
      <div>
        <Carousel
          controls={true}
          interval={null}
          fade={true}
          wrap={true}
          indicators={false}
        >
          <Carousel.Item>
            <iframe
              src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fmixtext%2Fvideos%2F342196699618356%2F&show_text=false&width=560&t=0"
              title="Facebook Video"
              width="500"
              height="300"
              style={{ border: "none", overflow: "hidden" }}
              allowFullScreen
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              
            ></iframe>
          </Carousel.Item>
          <Carousel.Item>
            <iframe
              title="YouTube Video 1"
              width="500"
              height="300"
              src="https://www.youtube.com/embed/f7u6PQWADjQ"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              onClick={() => setInterval(220000)}
            ></iframe>
          </Carousel.Item>
          <Carousel.Item >
            <iframe
              title="YouTube Video 2"
              width="500"
              height="300"
              src="https://www.youtube.com/embed/9g8i_F-7fuw"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </Carousel.Item>
        </Carousel>
      </div>
    </>
  );
};

export default CreativeContent;