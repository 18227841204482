import { useState, useEffect } from "react";
import ContactForm from "./ContactForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import "./ContactSection.css";

const ContactSection = () => {
  const [linkedinHover, setLinkedinHover] = useState(false);
  const [githubHover, setGithubHover] = useState(false);
  const [whatsappHover, setWhatsappHover] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollThreshold = 100;

      const isBottom =
        windowHeight + scrollY >= documentHeight - scrollThreshold;

      setIsAtBottom(isBottom);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="contact-wrapper d-flex flex-column justify-content-between ms-2 me-2">
      <h2 id="contact" className="mt-5">
        Contact Me
      </h2>
      <h6 className="w-75 mx-auto">
        I'm always open to new ideas, opportunities, or an interesting
        conversation over coffee
      </h6>

      <ContactForm />

      <footer className="d-flex justify-content-evenly text-light mt-5">
        <a
          href="https://www.linkedin.com/in/mati-breski/"
          target="_blank"
          rel="noreferrer"
          className="d-flex flex-column align-items-center text-decoration-none"
          onMouseEnter={() => setLinkedinHover(true)}
          onMouseLeave={() => setLinkedinHover(false)}
        >
          <FontAwesomeIcon
            icon={faLinkedin}
            flip={linkedinHover ? true : false}
            size="xl"
            style={{
              color: "#007ab5",
              backgroundColor: "white",
              padding: "1px",
            }}
          />
          <span className="align-self-start text-light ms-2">
            Let's connect
          </span>
        </a>
        <a
          href="https://github.com/MatiIL"
          target="_blank"
          rel="noreferrer"
          className="d-flex flex-column align-items-center text-decoration-none"
          onMouseEnter={() => setGithubHover(true)}
          onMouseLeave={() => setGithubHover(false)}
        >
          <FontAwesomeIcon
            icon={faGithub}
            size="xl"
            bounce={githubHover ? true : false}
          />
          <span className="align-self-start text-light ms-2">My code</span>
        </a>
        <a
          href="https://wa.me/+972547668607"
          target="_blank"
          rel="noreferrer"
          className="d-flex flex-column align-items-center text-decoration-none"
          onMouseEnter={() => setWhatsappHover(true)}
          onMouseLeave={() => setWhatsappHover(false)}
        >
          <FontAwesomeIcon
            icon={faWhatsapp}
            shake={whatsappHover ? true : false}
            style={{ color: "#25d46c" }}
            size="xl"
          />
          <span className="align-self-start text-light ms-2">Whatsapp me</span>
        </a>
      </footer>
    </div>
  );
};

export default ContactSection;
