import FullstackDev from "./FullStackDev";
import Research from "./Research";
import CreativeContent from "./CreativeContent";

const ActiveTabVisual = ({ activeTab }) => {
  let content;

  switch (activeTab) {
    case "fs-dev":
      content = (<FullstackDev />);
      break;
    case "research":
      content = (<Research />);
      break;
    case "creativity":
      content = (<CreativeContent />);
      break;
    default:
      content = null;
  }

  return <div className="text-light">{content}</div>;
};

export default ActiveTabVisual;
